<template>
  <div>
    <div class="">
      <!-- <h3>Информация о контракте</h3> -->
      <div class="card p-2 w-100">
        <table class="table w-100 table-bordered table-responsive">
          <thead>
            <tr>
              <th
                colspan="2"
                class="text-center"
              >
                Детали контракта 
                <span class="text-primary font-weight-bolder"> ({{ contract.id }})</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th >Срок рассрочки (месяц)</th>
              <td class="w-100">{{ contract.credit_months }}</td>
            </tr>
            <tr>
              <th>Cумма предоплаты</th>
              <td class="w-100">
                {{ contract.in_advance_payment_amount | formatPrice }} so'm
              </td>
            </tr>
            <tr v-if="contract.discount_amount">
              <th>Cумма скидка</th>
              <td class="w-100">{{ contract.discount_amount | formatPrice }} so'm</td>
            </tr>
            <tr>
              <th>Сумма рассрочки</th>
              <td class="w-100">{{ contract.credit_amount | formatPrice }} so'm</td>
            </tr>
            <tr>
              <th>День начала</th>
              <td class="w-100">{{ dateDotFormat(contract.start_date) }}</td>
            </tr>
            <tr>
              <th>День окончания</th>
              <td class="w-100">{{ dateDotFormat(contract.end_date) }}</td>
            </tr>
            <tr>
              <th>Контакты близких</th>
              <td class="w-100">
                <span v-if="contract.near_phone1">
                  +998{{ contract.near_phone1 }}
                </span>
                <span v-if="contract.near_phone2">
                  , +998{{ contract.near_phone2 }}
                </span>
              </td>
            </tr>
            <tr>
              <th>Кто зарегистрирован</th>
              <td class="w-100" v-if="contract.created_user">
                {{ contract.created_user.name }}
              </td>
            </tr>
            <tr>
              <th>Shartnoma raqami</th>
              <td class="w-100">
                <span class="text-primary font-weight-bolder"> {{ contract.id }}</span>
              </td>
            </tr>
            <template v-if="contract.is_rejected == 1">
              <tr>
                <th>Кто отклонил</th>
                <td class="w-100">
                  {{
                    contract.rejected_user ? contract.rejected_user.name : ""
                  }}
                </td>
              </tr>
              <tr>
                <th>Причина отклонения</th>
                <td class="w-100">
                  {{ contract.rejection_reason }}
                </td>
              </tr>
            </template>
            <tr v-can="'contracts.reject'">
              <td
                colspan="2"
                class="text-center"
              >
                <b-button
                  v-if="contract.status != 2 && contract.is_rejected == 0"
                  variant="outline-danger"
                  class="mr-1"
                  @click="reject_modal = true"
                >
                  Shartnomani bekor qilish
                </b-button>
                <b-button
                  v-else-if="contract.is_rejected == 1"
                  variant="danger"
                  class="mr-1"
                  disabled
                >
                  Shartnoma bekor qilingan
                </b-button>
                <b-button
                  v-if="contract.status == 2"
                  variant="warning"
                  disabled
                  class="mr-1"
                >
                  Shartnoma yopilgan
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="reject_modal">
      <b-modal
        v-model="reject_modal"
        size="md"
        centered
        hide-footer
        no-close-on-backdrop
        title="Shartnomani bekor qilish"
        @close="reject_modal = false"
        @cancel="reject_modal = false"
      >
        <RejectReason @submit="reject" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RejectReason from '@/components/modals/rejectReason.vue'

import { showToast } from '@/utils/toast'

export default {
  components: {
    RejectReason,
  },
  props: {
    orderId: {
      type: [Number, String],
      default: () => null,
    },
    contract: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      reject_modal: false,
    }
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  methods: {
    ...mapActions({
      rejectContract: 'contract/rejectContract',
    }),
    reject(reason) {
      const data = {
        contract_id: this.$route.params.id,
        rejection_reason: reason,
      }
      this.rejectContract(data).then(res => {
        if (res.success) {
          showToast('success', this.$t('Shartnoma bekor qilindi'))
          this.$router.push({ name: 'OrdersIndex' })
        }
      })
    },
  },
}
</script>

<style></style>
